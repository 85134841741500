// Here you can add other styles
.text-dashboard {
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  color: #000;
}
.text-sidebar-brand {
  font-style: normal;
  font-weight: 600;
  color: #874b31;
}
.bg-sidebar-brand {
  background-color: #ffffff;
  background: url('../assets/images/logosm.png') no-repeat center;
  background-size: 175px;
}
.text-user {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.bg-brown {
  background-color: #874b31;
  color: #ffffff;
}

.bg-green {
  background-color: #688009;
  color: #ffffff;
}

.text-label {
  color: #874b31;
  font-weight: 600;
}

.sidebar-nav .nav-link.active {
  font-weight: 600;
}

.nav-icon {
  font-weight: 600;
  font-size: 24px;
}

// DASHBOARD

.flex-container {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-around;
}

.bg-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(157, 159, 163, 0.13) 0px 0px 1px 1px;
}
.text-info-dashboard {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 24px;
  color: #000000;
}

.text-info-status {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.icon-arrow {
  font-size: 23px;
  color: #874b31;
}

.icon-action {
  text-decoration: none;
}

.text-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #676767;
  margin-bottom: 8px;
}

.text-total {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 54px;
  color: #000000;
}

.input-group-text {
  background-color: #ffffff;
  background-color: rgba(209, 115, 74, 0.19);
}

.icon-date {
  color: #874b31;
}

.form-date {
  background: rgba(209, 115, 74, 0.19);
  color: #874b31;
}

.bg-color-button {
  background-color: #874b31;
  border-color: #874b31;
}

.bg-color-button:hover {
  background: #fffbde;
  color: #874b31;
  border-color: #fffbde;
}

.color-link {
  color: #874b31;
}

.col-form-label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2a3739;
}

.text-area-width {
  height: 200px;
}

.text-confirm {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.btn-primary {
  color: #fff;
  background-color: #874b31;
  border-color: #874b31;
}

.btn-primary:hover {
  color: #fff;
  background-color: #a04e2b;
  border-color: #a04e2b;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #a04e2b;
  border-color: #a04e2b;
  box-shadow: 0 0 0 0.25rem #d49c84;
}
.btn-outline-primary {
  color: #874b31;
  border-color: #874b31;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #a04e2b;
  border-color: #a04e2b;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem #d49c84;
}
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #a04e2b;
  border-color: #a04e2b;
}
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem #d49c84;
}
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #874b31;
  background-color: transparent;
}

.title-info {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
}

.icon-title {
  font-size: 20px;
  color: #874b31;
}

.title-info {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.title-info-thin {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.detail-info {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #787878;
}
.detail-info-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #874b31;
}
.title-total {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}
.detail-total {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #874b31;
}

.width-img {
  width: 100px;
  height: 100px;
}

.bg-variasi {
  background: rgba(255, 208, 187, 0.82);
  color: #a04e2b;
  font-weight: 600;
  font-size: 20px;
}

.price-detail {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #874b31;
}

.name-product {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #000000;
}

.subdetail {
  font-weight: 400;
  font-size: 15px;
  color: #787878;
}

.subdetail-name {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.payment {
  font-weight: 400;
  font-size: 15px;
  color: #787878;
}

.payment-name {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #000000;
  margin-left: 300px;
}

hr {
  color: #874b31;
}

.form-title {
  font-weight: 600;
  font-size: 16px;
  color: #2a3739;
}
.image-item {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.body-popover {
  font-weight: 500;
  font-size: 12px;
  color: #0a1113;
}

.list-icon {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
}
.text-price-before {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #fc6e2d;
  text-decoration: line-through;
}

.width-banner {
  width: 416px;
  height: 200px;
  border-radius: 20px;
}

.logout-style {
  text-decoration: none;
  color: black;
}

.logout {
  cursor: pointer;
}

// CHAT

// @mixin mobile {
//   @media screen and (max-width: 480px) {
//     @content;
//   }
// }
// @mixin tablet {
//   @media screen and (max-width: 768px) {
//     @content;
//   }
// }
// @mixin laptop {
//   @media screen and (max-width: 1200px) {
//     @content;
//   }
// }

.formContainer {
  background-color: #a7bcff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .formWrapper {
    background-color: white;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    .logo {
      color: #5d5b8d;
      font-weight: bold;
      font-size: 24px;
    }

    .title {
      color: #5d5b8d;
      font-size: 12px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      input {
        padding: 15px;
        border: none;
        width: 250px;
        border-bottom: 1px solid #a7bcff;
        &::placeholder {
          color: rgb(175, 175, 175);
        }
      }

      button {
        background-color: #7b96ec;
        color: white;
        padding: 10px;
        font-weight: bold;
        border: none;
        cursor: pointer;
      }

      label {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #8da4f1;
        font-size: 12px;
        cursor: pointer;

        img {
          width: 32px;
        }
      }
    }
    p {
      color: #5d5b8d;
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.home {
  // background-color: #a7bcff;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    border: 1px solid white;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    // @include tablet {
    //  width: 90%;
    // }

    .sidebar {
      flex: 1;
      background-color: #fffbf9;
      position: relative;
      overflow-y: scroll;

      .navbar {
        display: flex;
        align-items: center;
        background-color: #bd7353;
        height: 50px;
        padding: 10px;
        justify-content: space-between;
        color: #ddddf7;

        .logo {
          font-weight: bold;
          // @include tablet {
          //   display: none;
          // }
        }

        .user {
          display: flex;
          gap: 10px;

          img {
            background-color: #ddddf7;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            object-fit: cover;
          }

          button {
            background-color: #5d5b8d;
            color: #ddddf7;
            font-size: 10px;
            border: none;
            cursor: pointer;
            // @include tablet {
            //   position: absolute;
            //   bottom: 10px;
            // }
          }
        }
      }
      .search {
        border-bottom: 1px solid gray;

        .searchForm {
          padding: 10px;

          input {
            background-color: transparent;
            border: none;
            color: black;
            outline: none;

            &::placeholder {
              color: lightgray;
            }
          }
        }
      }

      .userChatSearch {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: black;
        cursor: pointer;

        &:hover {
          background-color: #e4e0de;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }

        p {
          font-size: 12px;
          color: #858383;
        }
        .userChatInfo {
          display: flex;
          align-items: center;

          span {
            font-size: 18px;
            font-weight: 500;
          }
          p {
            font-size: 14px;
            color: #858383;

            &:hover {
              color: #676767;
            }
          }
        }
      }

      .userChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: black;
        cursor: pointer;
        justify-content: space-between;

        &:hover {
          background-color: #e4e0de;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }

        p {
          font-size: 12px;
          color: #858383;
        }
        .userChatInfo {
          display: flex;
          align-items: center;

          span {
            font-size: 16px;
            font-weight: 500;
          }
          p {
            font-size: 14px;
            color: #858383;

            &:hover {
              color: #676767;
            }
          }
        }
      }
    }
    .chat {
      flex: 2;

      .chatInfo {
        height: 50px;
        background-color: #fffbf9;
        box-shadow: inset 0px -1px 0px #e5e5ea;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        color: #20130d;
        font-weight: 600;
        .p {
          font-size: 12px;
        }
      }
      .chatIcons {
        display: flex;
        gap: 10px;

        img {
          height: 24px;
          cursor: pointer;
        }
      }

      .messages {
        background-color: #fffbf9;
        padding: 10px;
        height: calc(100% - 130px);
        overflow-x: hidden;
        overflow-y: scroll;

        .message {
          display: flex;
          gap: 20px;
          margin-bottom: 20px;

          .messageInfo {
            display: flex;
            flex-direction: column;
            color: gray;
            font-weight: 300;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .messageContent {
            max-width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            p {
              background-color: #e7e7e9;
              padding: 10px 20px;
              border-radius: 0px 10px 10px 10px;
              max-width: max-content;
            }

            img {
              width: 50%;
            }
          }

          &.owner {
            flex-direction: row-reverse;

            .messageContent {
              align-items: flex-end;
              p {
                background-color: #874b31;
                color: white;
                border-radius: 10px 0px 10px 10px;
              }
            }
          }
        }
      }

      .input {
        height: 50px;
        background-color: white;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
          width: 100%;
          border: none;
          outline: none;
          color: #2f2d52;
          font-size: 18px;

          &::placeholder {
            color: lightgray;
          }
        }

        .send {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            height: 24px;
            cursor: pointer;
          }

          button {
            border: none;
            padding: 10px 15px;
            align-items: center;
            margin-top: 15px;
            color: white;
            background-color: #874b31;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//DETAIL PESANAN BARU
.layout-pesanan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.card-order {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #fffbde;
  background-color: #fffbde;
}

.icon-chat {
  font-size: 20px;
}

.text-color-pp {
  color: #874b31;
}

.time-chat {
  font-size: 12px;
  color: #9c9592;
}

@media screen and (max-width: 768px) {
  .layout-pesanan {
    flex-direction: column;
  }
}

// INFORMASI
.title-news {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.text-periode {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #874b31;
}

.text-date {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #874b31;
}

.text-desc {
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  color: #000000;
  white-space: pre-line;
}

.text-note {
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #676b6c;
}

.line-info {
  border: 1px solid #c4c4c4;
}

.flex-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.promo-type {
  font-size: 12px;
}

.input-date-range-wrapper {
  height: 37px;
  position: relative;

  .react-datepicker-wrapper {
    display: block !important;
  }

  .react-datepicker {
    display: flex;
  }

  .append-icon {
    position: absolute;
    right: 8px;
    top: 10px;
    color: rgb(175, 175, 175);
  }
}

.title-info {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-bold {
  font-weight: 700;
}

.text-welcome {
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  color: #874b31;
}

.img-welcome {
  width: 70%;
}

.default-width {
  width: 50%;
}

.basic-single {
  z-index: 9999;
}

.has-error input {
  border-color: #f00;
}
.has-error .form-control {
  color: #f00;
}

.has-error-p {
  color: #f00;
}

.alert-container {
  position: fixed;
  top: 20;
  left: 35;
  right: 0;
  z-index: 9999;
}

.zindex-table {
  position: relative;
  z-index: 0;
}

.text-promo-line {
  font-size: 14px;
}

.text-color-note {
  color: #874b31;
}

//PROGRESS BAR
.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #eee;
  border-radius: 20px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #007bff; /* Warna progress bar */
  border-radius: 20px;
  transition: width 0.3s ease; /* Efek animasi */
}

.progress-semester {
  height: 100%;
  background-color: #6a810a; /* Warna progress bar */
  border-radius: 20px;
  transition: width 0.3s ease; /* Efek animasi */
}
.progress-kuartal {
  height: 100%;
  background-color: #874b31; /* Warna progress bar */
  border-radius: 20px;
  transition: width 0.3s ease; /* Efek animasi */
}
.progress-annual {
  height: 100%;
  background-color: #9b51e0; /* Warna progress bar */
  border-radius: 20px;
  transition: width 0.3s ease; /* Efek animasi */
}

.card-custom {
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
}

.title-card-omzet-month {
  font-size: 16px;
  font-weight: 600;
  color: #787878;
}

.text-achiev-month {
  color: #0072c3;
  font-size: 16px;
  font-weight: 600;
}

.text-achiev-semester {
  color: #6a810a;
  font-size: 16px;
  font-weight: 600;
}

.text-achiev-kuartal {
  color: #874b31;
  font-size: 16px;
  font-weight: 600;
}
.text-achiev-annual {
  color: #9b51e0;
  font-size: 16px;
  font-weight: 600;
}

.text-value-omzet {
  color: #676767;
  font-size: 12px;
}

.required {
  color: red;
}

.wrapper-class {
  padding: 20px;
  border: 1px solid #ccc;
}
.editor-class {
  background: #ffffff;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.img-zoom:hover {
  transform: scale(
    2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.img-zoom-in {
  width: 30%;
  overflow: hidden;
}

.img-zoom-in img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.img-zoom-in:hover img {
  transform: scale(2);
}

.img-profil {
  width: 50%;
}

.text-note {
  font-size: 15px;
  font-weight: 400;
  color: #874b31;
}

//IMAGE MAGNIFIER

.img-magnifier-container {
  position: relative;
}

.magnifier-img {
  width: auto;
  height: 450px;
}

.magnifier-image {
  width: 400px;
  height: 200px;
  border: 2px solid #fff;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 3s linear infinite;
}

.card-select {
  cursor: pointer;
}
